<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="visibleModal"
    >   
        <CForm id="images_form" method="POST" enctype="multipart/form-data">
            <CRow>
              <CCol md="12">
                <label>Selecciona las imagenes que deseas cargar el expediente del reporte: </label>
                <br /><br />
                <CInput
                    type="file"
                    name="image_1"
                    id="image_1"
                    :lazy="false"
                    :value.sync="$v.form.image.$model"
                    invalidFeedback="Este campo es obligatorio."
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <CInput
                    type="file"
                    name="image_2"
                    id="image_2"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <CInput
                    type="file"
                    name="image_3"
                    id="image_3"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <CInput
                    type="file"
                    name="image_4"
                    id="image_4"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <CInput
                    type="file"
                    name="image_5"
                    id="image_5"
                />
              </CCol>
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="imagesTicket" color="success" style="float: right;">Agregar Imagenes</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, maxLength, email, sameAs, helpers } from "vuelidate/lib/validators"
import ws from '../../services/tickets';
import login from '../../services/login';

export default {
  name: 'ImagesModal',
  props: {
    permissions: Array
  },
  data () {
    return {
      visibleModal: false,
      title: "",
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,
      fields: [
        {key: 'name', label: 'Nombre'},
        {key: 'actions', label: '', class: 'actions' }
      ]
    }
  },
  mounted: async function() {
    
  },
  methods: {
    showModal (ticket) {
      this.visibleModal = true;
      this.color = "success";
      this.title = 'Agregar Imagenes';
      this.isEdit = false;
      this.submitted = false;

      this.form = {
          ticket_id: ticket.id,
          ticket_code: ticket.code,
          image: ''
      }
    },
    async imagesTicket () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        const form = document.getElementById("images_form");
        const formData = new FormData(form);

        formData.append("ticket_id", this.form.ticket_id);
        formData.append("ticket_code", this.form.ticket_code);

        let response = await ws.imagesTicket(formData);

        if(response.type == "success"){
          this.$emit("uploaded", response.data );
          this.closeModal();
        }
      }
    },
    closeModal () {
      this.visibleModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          ticket_id: '',
          ticket_code: '',
          image: ''
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid || field.$model === '')
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      ticket_id: {},
      ticket_code: {},
      image: {
        required
      }
    }
  },
}
</script>
<style>
  .modal-footer{
    display: block !important;
  }
</style>