import axios from 'axios'

export default {
    get: () => axios.get('directions'),
    store: (data) => axios.post('directions/store', data),
    storeDirector: (data) => axios.post('directions/store-director', data),
    update: (data) => axios.post('directions/update', data),
    delete: (id) => axios.post('directions/delete', id),
    active: (id) => axios.post('directions/active', id),
    deactive: (id) => axios.post('directions/deactive', id),
    clearDirector: (id) => axios.post('directions/clear-director', id),
}