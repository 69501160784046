<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <span style="float: right; text-align: right;" v-if="ticket"><span style="font-weight: bold;">{{ticket.stage}}</span><br />{{ticket.date | date}}</span>
        <span style="font-weight: bold;">Reporte #{{ticket.number}}</span><br />
        <span>{{ticket.direction}}</span>
        <div class="card-header-actions">
          
        </div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <span style="font-weight: bold;">¿Quién lo reporta?</span><br />
      <div style="padding: 0 0 0 20px; margin-top: 10px;">{{ticket.citizen}} ( {{ticket.citizen_phone}} )</div><br />
      <span style="font-weight: bold;">¿Quién es el responsable de atenderlo?</span><br />
      <div style="padding: 0 0 0 20px; margin-top: 10px;">{{ticket.direction}} <span v-if="ticket.director">({{ticket.director}})</span></div><br />
      <span style="font-weight: bold;">¿Qué es lo que se reporta?</span><br />
      <div style="padding: 0 0 0 20px; margin-top: 10px;">{{ticket.description}}</div><br />
      <span style="font-weight: bold;">¿Cuál es la ubicación del problema reportado?</span><br />
      <div style="padding: 0 0 0 20px; margin-top: 10px;">{{ticket.location ? ticket.location : ticket.geolocation}}</div><br />
      <span style="font-weight: bold;">¿En que estado se encuentra el reporte?</span><br />
      <div style="padding: 0 0 0 20px; margin-top: 10px;">{{ticket.stage}}</div><br /><br />
      <CCard>
        <CCardHeader>
          <slot name="header">
            <span style="font-weight: bold;">IMAGENES DEL REPORTE</span>
            <div class="card-header-actions">
              
            </div>
          </slot>
        </CCardHeader>
        <CCardBody>
          <div v-if="ticket.before_images != undefined && ticket.before_images.length > 0 || ticket.after_images != undefined &&  ticket.after_images.length > 0">
            <div v-if="ticket.before_images.length > 0">
              <span style="font-weight: bold;">Imagenes del Problema (antes): </span><br /><br />
              <CRow>
                <CCol md="3" v-for="(image,index) in ticket.before_images" :key="'bi_'+index">
                  <img :src="image.url" style="width: 100%; margin-bottom: 10px;" />
                </CCol>
              </CRow>
            </div>
            <div v-if="ticket.before_images.length > 0 && ticket.after_images.length > 0"><br /><br /></div>
            <div v-if="ticket.after_images.length > 0">
              <span style="font-weight: bold;">Imagenes de Seguimiento (después): </span><br /><br />
              <CRow>
                <CCol md="3" v-for="(image,index) in ticket.after_images" :key="'ai_'+index">
                  <img :src="image.url" style="width: 100%; margin-bottom: 10px;" />
                </CCol>
              </CRow>
            </div>
          </div>
          <div v-if="(ticket.before_images == undefined || ticket.before_images.length == 0) && (ticket.after_images == undefined || ticket.after_images.length == 0)">NO SE HAN REGISTRADO IMAGENES</div>
        </CCardBody>
      </CCard>
      <CCard>
        <CCardHeader>
          <slot name="header">
            <span style="font-weight: bold;">HISTORIAL DE EVENTOS</span>
            <div class="card-header-actions"></div>
          </slot>
        </CCardHeader>
        <CCardBody>
          <div v-if="ticket.events != undefined && ticket.events.length > 0">
            <div v-for="(event,index) in ticket.events" :key="'e_'+index">
              <span style="float: right; font-weight: bold;">{{event && event.citizen_id > 0 ? (event.citizen ? event.citizen.name : 'N / D') : (event.user ? event.user.name : 'N / D')}}</span><br />
              <span style="font-weight: bold; ">{{event.title}}</span><br />
              <span style="font-size: 12px;">{{event.extended_date}}</span><br /><br />
              <div style="padding-left: 20px;">
                {{event.description}}
                <div v-if="event.name == 'image'"><br /><img :src="event.image.url" style="width: 200px;" /></div>
                <div v-if="event.name == 'comments' || event.name == 'closed' || event.name == 'accepted' || event.name == 'rejected' || event.name == 'in_progress' || event.name == 'done'" style="padding-left: 20px; font-style: italic; font-size: 14px; font-weight: bold;"><br />{{event.comments.message}}</div>
              </div>
              <br />
              <hr v-if="!(index == ticket.events.length - 1)" />
            </div>
          </div>
          <div v-if="ticket.events == undefined || ticket.events.length == 0">NO SE HAN REGISTRADO EVENTOS</div>
        </CCardBody>
      </CCard>
      <div style="float: right;" v-if="(ticket.status == 'new' || ticket.stage_code == 'new') && !this.public && permissions['p11']">
        <CButton color="info" size="sm" @click="validationModal(ticket)" style="margin-right: 5px;">Validar Reporte</CButton>
      </div>
      <div style="float: right;" v-if="(ticket.status == 'accepted' || ticket.stage_code == 'accepted') && !this.public && permissions['p11']">
        <CButton color="info" size="sm" @click="transferModal(ticket)" style="margin-right: 5px;">Redireccionar</CButton>
        <CButton color="danger" size="sm" @click="closeModal(ticket)" style="margin-right: 5px;">Cerrar Reporte</CButton>
        <CButton color="success" size="sm" @click="initModal(ticket)" style="margin-right: 5px;">Iniciar Atención</CButton>
      </div>
      <div style="float: right;" v-if="(ticket.status == 'in_progress' || ticket.stage_code == 'in_progress') && !this.public && permissions['p11']">
        <CButton color="warning" size="sm" @click="messageModal(ticket)" style="margin-right: 5px;">Comentarios</CButton>
        <CButton color="info" size="sm" @click="imagesModal(ticket)" style="margin-right: 5px;">Imagenes</CButton>
        <CButton color="success" size="sm" @click="finishModal(ticket)" style="margin-right: 5px;">Terminar Reporte</CButton>
      </div>
    </CCardBody>

    <ValidationModal ref="refValidationModal" @accepted="ticketAccepted" @rejected="ticketRejected"></ValidationModal>
    <RedirectModal ref="refRedirectionModal" @transfer="ticketTransfered"></RedirectModal>
    <CloseModal ref="refCloseModal" @closed="ticketClosed"></CloseModal>
    <InitModal ref="refInitModal" @initiated="ticketInitiated"></InitModal>
    <FinishModal ref="refFinishModal" @finished="ticketFinished"></FinishModal>
    <MessageModal ref="refMessageModal" @messaged="ticketMessaged"></MessageModal>
    <ImagesModal ref="refImagesModal" @uploaded="ticketUploaded"></ImagesModal>
  </CCard>
</template>

<script>
import ws from '../../services/tickets';
import store from '../../store'
import moment from 'moment';
import moment_timezone from 'moment-timezone';
import ValidationModal from './ValidationModal.vue'
import CloseModal from './CloseModal.vue'
import InitModal from './InitModal.vue'
import FinishModal from './FinishModal.vue'
import MessageModal from './MessageModal.vue'
import ImagesModal from './ImagesModal.vue'
import RedirectModal from './RedirectModal.vue'

export default {
  name: 'TicketDetails',
  components: { ValidationModal, CloseModal, InitModal, FinishModal, MessageModal, ImagesModal, RedirectModal },
  props: {
    login: {
      type: Boolean,
      default: false
    },
    public: {
      type: Boolean,
      default: false
    },
    validation: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      toasts: [],
      code: '',
      ticket: {

      },
      fields: [
          {key: 'number', label: 'No.'},
      ],
      permissions: null
    }
  },
  mounted: async function() {
    this.loading();

    this.code = await this.$route.params.code;
    
    if(this.public){
      let response = await ws.getPublicTicketByCode(this.code);

      if(response.type == "success"){
        this.ticket = response.data; 
      }
    }
    else{
      this.permissions = JSON.parse(localStorage.getItem("permissions"));

      if(this.validation){
        let response = await ws.getValidationTicketByCode(this.code);

        if(response.type == "success"){
          this.ticket = response.data; 
        }
      }
      else{
        let response = await ws.getTicketByCode(this.code);

        if(response.type == "success"){
          this.ticket = response.data; 
        }
      }
    }

    this.loaded();
  },
  methods: {
    validationModal (ticket) {
      this.$refs.refValidationModal.showModal(ticket);
    },
    ticketAccepted (ticket) {
      this.ticket = ticket;

      this.showToast("success", "El reporte ha sido aceptado correctamente.");
    },
    ticketRejected (ticket) {
      this.ticket = ticket;

      this.showToast("success", "El reporte ha sido rechazado correctamente.");
    },
    closeModal (ticket) {
      this.$refs.refCloseModal.showModal(ticket);
    },
    ticketClosed (ticket) {
      this.ticket = ticket;

      this.showToast("success", "El reporte ha sido cerrado correctamente.");
    },
    initModal (ticket) {
      this.$refs.refInitModal.showModal(ticket);
    },
    ticketInitiated (ticket) {
      this.ticket = ticket;

      this.showToast("success", "La atención del reporte ha iniciado correctamente.");
    },
    finishModal (ticket) {
      this.$refs.refFinishModal.showModal(ticket);
    },
    ticketFinished (ticket) {
      this.ticket = ticket;

      this.showToast("success", "La atención del reporte ha concluido correctamente.");
    },
    messageModal (ticket) {
      this.$refs.refMessageModal.showModal(ticket);
    },
    ticketMessaged (ticket) {
      this.ticket = ticket;

      this.showToast("success", "Los comentarios han sido agregados al reporte correctamente.");
    },
    imagesModal (ticket) {
      this.$refs.refImagesModal.showModal(ticket);
    },
    ticketUploaded (ticket) {
      this.ticket = ticket;

      this.showToast("success", "Los comentarios han sido agregados al reporte correctamente.");
    },
    transferModal (ticket) {
      this.$refs.refRedirectionModal.showModal(ticket);
    },
    ticketTransfered (ticket) {
      this.ticket = ticket;

      this.showToast("success", "El reporte ha sido transferido a la dirección correspondiente.");
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  },
  watch: {
    range: async function(){
      await this.changeFilters();
    }
  }
}
</script>
<style>
  .date-picker input{
    height: 32px;
    font-size: 12px !important;
  }

  select{
    height: 32px;
    font-size: 12px !important;
    margin-bottom: 0px;
  }
</style>