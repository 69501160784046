import axios from 'axios'

export default {
    get: () => axios.get('tickets'),
    getByCode: (code, direction_id) => axios.get('tickets-'+code+"?direction="+direction_id),
    getTicketByCode: (code) => axios.get('ticket-'+code),
    getPublicTicketByCode: (code) => axios.get('get-ticket-'+code),
    getValidationTicketByCode: (code) => axios.get('validation-ticket/'+code),
    
    store: (data) => axios.post('tickets/store', data),
    update: (data) => axios.post('tickets/update', data),
    delete: (id) => axios.post('tickets/delete', id),

    validationTicket: (data) => axios.post('validation-ticket', data),
    validateTicket: (data) => axios.post('validate-ticket', data),
    closeTicket: (data) => axios.post('close-ticket', data),
    initTicket: (data) => axios.post('init-ticket', data),
    finishTicket: (data) => axios.post('finish-ticket', data),
    messageTicket: (data) => axios.post('message-ticket', data),
    imagesTicket: (data) => axios.post('images-ticket', data),
    transferTicket: (data) => axios.post('transfer-ticket', data),

    getFilteredTickets: (dates, direction, stage) => axios.post('filtered-tickets', {dates: dates, direction: direction, stage: stage}),
    getDirections: () => axios.get('directions'),
    getStages: () => axios.get('stages'),
    getOpenStages: () => axios.get('stages/open'),
}