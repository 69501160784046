<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="validationModal"
    >   
        <CForm>
            
            <CRow>
              <CCol md="12">
                <label>Escribe el motivo por el que se rechaza el reporte: </label>
                <CTextarea
                  :value.sync="$v.form.message.$model"
                  :isValid="checkIfValid('message')"
                  placeholder=""
                  autocomplete="given-message"
                  invalidFeedback="Este campo es obligatorio."
                />
              </CCol>
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="acceptTicket" color="success" style="float: right;">Aceptar</CButton>
          <CButton @click="rejectTicket" color="danger" style="float: right;">Rechazar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, maxLength, email, sameAs, helpers } from "vuelidate/lib/validators"
import ws from '../../services/tickets';
import login from '../../services/login';

export default {
  name: 'ValidationModal',
  props: {
    permissions: Array
  },
  data () {
    return {
      validationModal: false,
      title: "Validar Reporte",
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,
      fields: [
        {key: 'name', label: 'Nombre'},
        {key: 'actions', label: '', class: 'actions' }
      ]
    }
  },
  mounted: async function() {
    
  },
  methods: {
    showModal (ticket) {
      this.validationModal = true;
      this.color = "info";
      this.title = 'Validar Reporte';
      this.isEdit = false;
      this.submitted = false;

      this.form = {
          ticket_id: ticket.id,
          ticket_code: ticket.code,
          user_id: ticket.activator !== undefined ? ticket.activator.id : 0,
          type: '',
          message: ''
      }
    },
    async acceptTicket () {
      this.$v.$touch();
      
      if (this.isValid) {
        this.submitted = true;
        this.form.type = "accept";

        let res = await login.validate({token: localStorage.getItem("token")});

        let response = false;
        if(res.type == "success"){
          response = await ws.validationTicket(this.form);
        }
        else{
          response = await ws.validateTicket(this.form);
        }

        if(response.type == "success"){
          this.$emit("accepted", response.data );
          this.closeModal();
        }
      }
    },
    async rejectTicket () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        this.form.type = "reject";
        
        let res = await login.validate({token: localStorage.getItem("token")});

        let response = false;
        if(res.type == "success"){
          response = await ws.validationTicket(this.form);
        }
        else{
          response = await ws.validateTicket(this.form);
        }

        if(response.type == "success"){
          this.$emit("rejected", response.data );
          this.closeModal();
        }
      }
    },
    closeModal () {
      this.validationModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          ticket_id: '',
          ticket_code: '',
          user_id: '',
          type: '',
          message: ''
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid || field.$model === '')
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      ticket_id: {
        
      },
      ticket_code: {
        
      },
      user_id: {
        
      },
      type: {
        
      },
      message: {
        required
      }
    }
  },
}
</script>
<style>
  textarea{
    height: 150px !important;
  }

  .modal-footer{
    display: block !important;
  }
</style>