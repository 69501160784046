<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="directionModal"
    >   
        <CForm>
            <CRow>
              <CCol md="12">
                <CSelect
                  label="Dirección: "
                  :value.sync="$v.form.direction_id.$model"
                  :options="directionsOptions"
                  invalidFeedback="Este es un campo obligatorio."
                />
              </CCol>
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color" style="float: right;">Redireccionar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, maxLength, email, sameAs, helpers } from "vuelidate/lib/validators"
import ws_directions from '../../services/directions';
import ws from '../../services/tickets';

export default {
  name: 'RedirectModal',
  props: {
    permissions: Array
  },
  data () {
    return {
      directionModal: false,
      title: "Redireccionar Reporte",
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,
      fields: [
        {key: 'name', label: 'Nombre'},
        {key: 'actions', label: '', class: 'actions' }
      ],
      directions: [],
      directionsOptions: []
    }
  },
  mounted: async function() {
    let response = await ws_directions.get(); 

    if(response.type == "success"){
      this.directions = response.data;
    }

    this.directionsOptions = await this.$parseSelectOptionsOrdered(this.directions, "id", "name", [{"label": "","value":""}]);
  },
  methods: {
    showModal (ticket) {
      this.directionModal = true;
      this.color = "info";
      this.title = 'Redireccionar Reporte';
      this.isEdit = false;
      this.submitted = false;

      this.form = {
          id: ticket.id,
          direction_id: ticket.direction_id
      }
    },
    async store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        let response = await ws.transferTicket(this.form);

        if(response.type == "success"){
          this.$emit("transfer", response.data );
        }

        this.closeModal();
      }
    },
    closeModal () {
      this.directionModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          id: 0,
          direction_id: ""
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid || field.$model === '')
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      direction_id: {
        required
      }
    }
  },
}
</script>